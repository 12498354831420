#table-of-contents {
  padding-bottom: 0;
  margin: 0;
}

#markdown-toc {
  background-color: $gray;
  color: $blue;
  margin-left: 0;
  padding: 1rem 1rem 1rem 2rem;
  margin-bottom: 0;
}
