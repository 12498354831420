/*- Typography -*/
//
// Based on the typographic scale: 12, 14, 16, 18, 21, 24, 36, 48, 60, 72.
//

@font-face {
    font-family: 'CircularStd';
    src: url('../assets/fonts/CircularStd-Book.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../assets/fonts/CircularStd-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CircularStd';
    src: url('../assets/fonts/CircularStd-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


body {
	font-family: 'Source Sans Pro', sans-serif;
	// letter-spacing: 0.01em;
}

pre, code {
	font-family: 'Source Code Pro', monospace;
}

pre {
	font-size: 0.9rem;
	line-height: 1.2rem;
}

p code {
	margin: 0 0.25ex;
	font-size: 90%;
}

/*- Typography for medium and small screen, based on 16px font-size -*/

p, ul, ol {
	margin-bottom: 0.5 * $baseline-height;
}

h1 {
	font-size: 2rem;
	line-height: 4.5rem;

	article .content & {
		padding-bottom: $baseline-height;
	}
}

h2 {
	font-family: 'CircularStd', sans-serif;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 3rem;
	article .content & {
		padding-bottom: 0.5 * $baseline-height;
	}
}

h3 {
	font-family: 'CircularStd', sans-serif;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 2rem;
	article .content & {
		padding-bottom: 0.5 * $baseline-height;
	}
}

h4, h5, h6 {
	font-family: 'CircularStd', sans-serif;
	font-weight: 700;
	font-size: 1.10rem;
	line-height: 1.75rem;

	article .content & {
		// Using padding to avoid collapse with previous element margin
		padding-top: 0.25 * $baseline-height;
		margin-bottom: 0.25 * $baseline-height;
	}
}

blockquote {
	font-style: italic;
	margin: 1.5em; /* 24px/18px */
	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
	    -ms-border-radius: 4px;
          border-radius: 4px;
	background-color: darken($background-color, 5%);
	padding: 0 1.5em; /* 24px/18px */
	p, ul, ol {
		padding: 1.5em 0; /* 24px/18px */
	}
}

// Indent lists so they are inside the section
li, ul {
    margin-left: 1.5em;
}

li > li, ul > ul {
    margin-left: 1.5em;
}

// Make sure that our footnote markers don't affect line-height
sup {
  line-height: 0;
}
