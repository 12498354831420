/*- Custom style -*/


/*- colors -*/

@import "variables";

/*- formatting -*/

%colored-links {
  a {
    color: $link-color;
    text-decoration: none;
    font-weight: 700;
    &:hover,
    &:focus {
      color: darken($link-color, 5%);
    }
  }
}

.content {
  @extend %colored-links;

  &:after {
    display: block;
    content: '';
    width: 5rem;
    height: 1px;
    margin: 23px auto;
    background-color: lighten($text-color, 70%);
  }

  ul, ol {
    ul, ol {
      margin-left: 4ex;
      margin-bottom: 0;
    }
  }

  blockquote {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    p, ul, ol {
      padding: 0.1rem;
      font-style: normal;
    }
    pre {
      font-style: normal;
    }
  }
}

div.author {
  @extend %colored-links;

  display: flex;
  padding-bottom: 20px;

  img {
    border-radius: 50%;
    object-fit: cover;
    height: 70px;
    width: 70px;
    margin-right: 10px;
    margin-left: 0px;
  }
  span {
    font-style: italic;
  }
}

table {
    border: solid 1px #DDEEEE;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 13px;
}
thead th {
    background-color: $main-color;
    color: $background-color;
    border: solid 1px $main-color;
    padding: 10px;
    text-align: left;
}
tbody td {
    border: solid 1px $main-color;
    color: #333;
    padding: 10px;
    text-shadow: 1px 1px 1px #fff;
}

.contribute-section {
  &.container {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 120%;
    margin: 30px 0;
  }
  .form {
    margin-top: 20px;
    margin-bottom: 10px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}

.svg-icon {
  width: 16px;
  height: 16px;
  display: inline-block;
  fill: currentColor;
  padding: 6px 4px 3px 5px;
  vertical-align: text-bottom;
  box-sizing: content-box;
}

.svg-icon.rss {
  color: $rss-color;
}
.svg-icon.twitter {
  color: $twitter-color;
}
.svg-icon.linkedin {
  color: $linkedin-color;
}
.svg-icon.github {
  color: $github-color;
}

figure {
  margin-top: 40px;
}

figcaption {
  margin-top: -20px;
  text-align: center;
  font-size: 12px;
}

:root {
  --notificationBackground: #faaf4e;
  --notificationColor: #fff;
}

#nav-menu {
  top: 35px;
  margin-top: 35px;
  width: 30px;
  position: sticky;
  margin-left: auto;
}

.menu-open ~ #nav-menu {
  margin-top: 0;
  top: 35px;
  position: fixed;
}

.banner-notifications {
  margin: 0;
  list-style: none;
  display: none;
}

.banner-notification {
  position: relative;
  margin: 0;
  margin-bottom: 10px;
  background-color: var(--notificationBackground);
  color: var(--notificationColor);
  padding: 10px 50px 10px 20px;

  p {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus,
    &:active {
      color: inherit;
      text-decoration: none;
    }
  }
}

.banner-notification-close {
  background-color: color-mix(in srgb, var(--notificationBackground),#000 30%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  top: 15px;
  border: none;
  padding: 0;
  box-shadow: none;
  transform: rotate(-45deg);
  cursor: pointer;

  &::after,
  &::before {
    content: '';
    width: 10px;
    height: 1px;
    background-color: var(--notificationColor);
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transition: all 0.1s linear;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  }

  &:hover {
    &::after,
    &::before {
      width: 12px;
    }
  }

  &::before {
    transform: translateX(-50%) translateY(-50%) ;
  }
}

