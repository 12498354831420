$orange-color: #f66a0a !default;
$twitter-color: #1da1f2;
$github-color: #000000;
$rss-color: $orange-color;
$linkedin-color: #0077B5;


$blue: #141443;
$gray: rgba(20, 24, 69, 0.15);
$white: white;
